
import { defineComponent, defineProps, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { themeLightLogo } from "@/core/helpers/config";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    email: String,
    password: String,
  },
  computed: {
    newPasswordFieldType() {
      if (this.newPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
    passwordFieldType() {
      if (this.passwordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
  },
  methods: {
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleNewPasswordVisible() {
      this.newPasswordVisible = !this.newPasswordVisible;
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const passwordVisible = ref(false);
    const newPasswordVisible = ref(false);
    const submitButton = ref<HTMLElement | null>(null);

    if (!props.email || !props.password) {
      router.push({ name: "sign-in" });
    }
    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .required("Este campo es requerido")
        .label("Username"),
      password: Yup.string()
        .min(8, "Debe contener al menos 8 caracteres.")
        .required("Este campo es requerido")
        .label("Contraseña"),
      newPassword: Yup.string()
        .min(8, "Debe contener al menos 8 caracteres.")
        .required("Este campo es requerido")
        .label("Nueva contraseña"),
    });

    const usernameField = ref(props.email);
    const passwordField = ref(props.password);

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors

      store
        .dispatch(Actions.UPDATE_PASSWORD, {
          email: values.email,
          password: values.password,
          newPassword: values.newPassword,
        })
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .catch((data) => {
          Swal.fire({
            text: store.getters.getErrors[0]
              ? store.getters.getErrors[0]
              : store.getters.getErrors.newPassword,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Volver",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      usernameField,
      passwordField,
      passwordVisible,
      newPasswordVisible,
      onSubmitLogin,
      login,
      themeLightLogo,
      submitButton,
    };
  },
});
